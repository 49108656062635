import React from 'react';
import moment from 'moment';
import styles from './styles.module.css';

const Footer: React.FC = () => (
  <footer>
    <div className={styles.footer_content}>{moment().format('YYYY')}</div>
  </footer>
);

export default Footer;
