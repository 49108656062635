import React, { ReactNode } from 'react';
import ArticleHeader from 'components/ui-kit/ArticleHeader';
import ContactList from 'components/ContactList';
import EducationList from 'components/EducationList';
import SkillList from 'components/SkillList';
import OrganizationList from 'components/OrganizationList';
import LanguageList from 'components/LanguageList';
import ProjectList from 'components/ProjectList';

import ava from 'assets/images/ava.jpg';
import styles from './styles.module.css';

interface Props {
  t: (arg0: string) => ReactNode;
}

const Main: React.FC<Props> = ({ t }) => (
  <main>
    <div className={styles.main_info}>
      <div className={styles.photo_wrapper}>
        <img src={ava} alt="avatar" />
      </div>
      <div className={styles.profile_info}>
        <h1>{'Ilya \n  Huraliuk'}</h1>
        <h2>Full-stack JS developer</h2>
      </div>
    </div>
    <div className={styles.common_info}>
      <div>
        <ArticleHeader>{t('main.articleTitle.profile')}</ArticleHeader>
        <div className={styles.profile_details}>
          <p className={styles.summary}>{t('main.summary')}</p>
          <ContactList />
        </div>
        <ArticleHeader>{t('main.articleTitle.education')}</ArticleHeader>
        <EducationList />
        <ArticleHeader>{t('main.articleTitle.organization')}</ArticleHeader>
        <OrganizationList />
        <ArticleHeader>{t('main.articleTitle.skills')}</ArticleHeader>
        <SkillList />
        <ArticleHeader>{t('main.articleTitle.languages')}</ArticleHeader>
        <LanguageList />
      </div>
      <div>
        <ArticleHeader>{t('main.articleTitle.workExp')}</ArticleHeader>
        <ProjectList />
      </div>
    </div>
  </main>
);

export default Main;
