import React from 'react';
import styles from './styles.module.css';

const ArticleHeader: React.FC = ({ children }) => (
  <div className={styles.article_header}>
    {children}
  </div>
);

export default ArticleHeader;
