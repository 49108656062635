import React from 'react';
import { projects } from 'constants/projects';
import styles from './ProjectList.module.css';

const ProjectList: React.FC = () => (
  <div className={styles.project_list}>
    {projects.map(({
      title, description, position, timeActivity, responsibilities, technologies,
    }) => (
      <div key={title} className={styles.project_wrapper}>
        <div className={styles.project_header}>
          <span className={styles.title}>{title}</span>
          <span className={styles.timeActivity}>{timeActivity}</span>
        </div>
        <p className={styles.position}>{position}</p>
        <p className={styles.description}>{description}</p>
        <ul className={styles.responsibilities_list}>
          {responsibilities.map((r: string) => <li key={`${title}-${r}`}>{r}</li>)}
        </ul>
        <div className={styles.skill_list}>
          {technologies.map((tech: string) => (
            <span key={`${title}-${tech}`} className={styles.technology}>{tech}</span>
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default ProjectList;
