import LocationImage from 'assets/images/emogi/location.png';
import MailImage from 'assets/images/emogi/mail.png';
import LinkImage from 'assets/images/emogi/link.png';

export const contacts = [
  {
    icon: LocationImage,
    value: 'Warsaw, Poland',
  },
  {
    icon: MailImage,
    value: 'ilya@hurali.uk',
    type: 'mail',
  },
  {
    icon: LinkImage,
    value: 'github.com/razmki',
    type: 'link',
  },
  {
    icon: LinkImage,
    value: 'codewars.com/users/razMki',
    type: 'link',
  },
];
