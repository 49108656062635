import React from 'react';
import { educations } from 'constants/educations';
import styles from './Education.module.css';

const EducationList: React.FC = () => (
  <div>
    {educations.map(({
      title, university, educationDate, additionalLink,
    }) => (
      <div key={title} className={styles.education_wrapper}>
        <p className={styles.title}>{title}</p>
        <p className={styles.main_info}>{`${university} / ${educationDate}`}</p>
        {additionalLink && (
        <a
          className={styles.link}
          href={additionalLink}
          target="_blank"
          rel="noreferrer"
        >
          {additionalLink}
        </a>
        )}
      </div>
    ))}
  </div>
);

export default EducationList;
