/* eslint-disable */
export const getGameActions = () => {
  let newAsteroidTimeOut;
  let interval;
  let arrayOfAsteroids = [];
  const startGame = () => {
    const defer = document.querySelector('.def');
    let currentAngle = 0;
    let backSide = -1;
    let speed = 1;
    let score = 0;
    const scoresNode = document.querySelector('#score');
    scoresNode.innerText = score;
    let lifes = 3;
    const container = document.querySelector('.container');
    for (let j = 0; j < 3; j++) {
      const div = document.createElement('div');
      div.classList.add('lifes');
      container.insertBefore(div, defer);
    }
    let arrayOfLifes = document.querySelectorAll('.lifes');
    (function animationDefer(elem) {
      interval = setInterval(() => {
        const vx = Math.cos(currentAngle) * 90;
        const vy = Math.sin(currentAngle) * 90;
        elem.style.left = `${window.innerWidth / 2 + vy - 15}px`;
        elem.style.top = `${window.innerHeight / 2 + vx - 15}px`;
        currentAngle += 0.05 * backSide * speed;
      }, 1000 / 60);
    }(defer));
    const body = document.querySelector('body');
    let flag = true;
  
    body.addEventListener('keydown', (e) => {
      const { keyCode } = e;
      if (keyCode === 32) {
        if (flag) {
          speed = 2;
          backSide *= -1;
        }
        flag = false;
      }
    });
    body.addEventListener('keyup', (e) => {
      const { keyCode } = e;
      if (keyCode === 32) {
        flag = true;
        speed = 1;
      }
    });
  
    function generateRandomInteger(n) {
      return Math.round(Math.random() * 1000) % n;
    }
    const xCenter = window.innerWidth / 2;
    const yCenter = window.innerHeight / 2;
    class Asteroid {
      constructor() {
        const side = generateRandomInteger(4);
        switch (side) {
          case 0:
            this.x = -30;
            this.y = generateRandomInteger(window.innerHeight);
            break;
          case 1:
            this.y = -30;
            this.x = generateRandomInteger(window.innerWidth);
            break;
          case 2:
            this.y = generateRandomInteger(window.innerHeight);
            this.x = window.innerWidth;
            break;
          case 3:
            this.y = window.innerHeight;
            this.x = generateRandomInteger(window.innerWidth);
            break;
          default:
            break;
        }
        this.isYReverse = (this.y >= 300);
        this.isXReverse = (this.x >= 300);
        this.speed = generateRandomInteger(2) + 0.5;
        this.length = Math.sqrt((xCenter - this.x) ** 2 + (yCenter - this.y) ** 2);
        this.angle = this.isYReverse ? Math.asin((xCenter - this.x) / this.length) : Math.acos((xCenter - this.x) / this.length);
        this.element = document.createElement('div');
        this.element.classList.add('asteroid');
        this.element.style.left = `${this.x}px`;
        this.element.style.top = `${this.y}px`;
        container.appendChild(this.element);
        this.int = setInterval(this.move.bind(this), 1000 / 60);
      }
  
      move() {
        if (Math.sqrt((this.x - ((window.innerWidth / 2) - 15)) ** 2 + (this.y - ((window.innerHeight / 2) - 15)) ** 2) <= 75) {
          lifes -= 1;
          container.removeChild(arrayOfLifes[0]);
          arrayOfLifes = document.querySelectorAll('.lifes');
          this.destroy();
        }
        this.length -= this.speed;
        this.x = (xCenter - (Math.cos(this.angle) * this.length) * (this.isYReverse && this.isXReverse ? -1 : 1));
        this.y = (yCenter - (Math.sin(this.angle) * this.length) * (this.isYReverse ? -1 : 1));
        this.element.style.left = `${this.x}px`;
        this.element.style.top = `${this.y}px`;
        if (Math.sqrt((this.x - parseInt(defer.style.left)) ** 2 + (this.y - parseInt(defer.style.top)) ** 2) <= 30) {
          this.destroy();
          score += 1;
          scoresNode.innerText = score;
        }
      }
  
      destroy() {
        clearInterval(this.int);
        const boom = document.createElement('div');
        boom.classList.add('boom');
        boom.style.left = `${parseInt(this.element.style.left)}px`;
        boom.style.top = `${parseInt(this.element.style.top)}px`;
        container.removeChild(this.element);
        container.appendChild(boom);
        setTimeout(() => {
          container.removeChild(boom);
        }, 300);
        arrayOfAsteroids.splice(arrayOfAsteroids.indexOf(this), 1);
        if (lifes === 0) {
          lifes = 1;
          clearTimeout(newAsteroidTimeOut);
          clearInterval(interval);
          for (let i = arrayOfAsteroids.length - 1; i >= 0; i--) {
            arrayOfAsteroids[i].destroy();
          }
          arrayOfAsteroids.length = 0;
          const resultScore = document.querySelector('.result_score');
          resultScore.innerText = score;
          const playAgain = document.querySelector('.play_again');
          playAgain.style.display = 'block';
          body.addEventListener('keydown', function restart(e) {
            if (e.keyCode === 32) {
              const modalWindow = document.querySelector('.play_again');
              modalWindow.style.display = 'none';
              e.target.removeEventListener(e.type, restart);
              startGame();
            }
          });
        }
      }
    }
    function createAsteroid() {
      newAsteroidTimeOut = setTimeout(() => {
        arrayOfAsteroids.push(new Asteroid());
        createAsteroid();
      }, generateRandomInteger(500) + 1000);
    }
    createAsteroid();
  }
  
  

  const stopGame = () => {
    clearTimeout(newAsteroidTimeOut);
    clearInterval(interval);
    for (let i = arrayOfAsteroids.length - 1; i >= 0; i--) {
      arrayOfAsteroids[i].destroy();
    }
    arrayOfAsteroids.length = 0;
  }

  return { startGame, stopGame }
};
