import React from 'react';
import { organizations } from 'constants/organizations';
import styles from './OrganizationList.module.css';

const SkillList: React.FC = () => (
  <div className={styles.skill_list}>
    {organizations.map(({
      title, position, timeActivity, location,
    }) => (
      <div key={title} className={styles.organization_wrapper}>
        <div>
          <span className={styles.title}>{title}</span>
          <span className={styles.timeActivity}>{` (${timeActivity})`}</span>
        </div>
        <p className={styles.position}>{position}</p>
        <p className={styles.location}>{location}</p>
      </div>
    ))}
  </div>
);

export default SkillList;
