/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import defender from 'assets/images/asteroids/defender.png';
import earth from 'assets/images/asteroids/earth.png';
import './styles.css';

import { getGameActions } from './gameScript';

const Asteroids: React.FC = () => {
  const ref = useRef(null);
  const refNewGame = useRef(null);

  const { startGame, stopGame } = getGameActions();

  useEffect(() => {
    const element = ref.current;
    const modalNewWindow = refNewGame.current;

    const handleClick = () => {
      modalNewWindow.style.display = 'none';
      startGame();
    };

    if (element) element.addEventListener('click', handleClick);

    return () => {
      if (element) element.removeEventListener('click', handleClick);
      stopGame();
    };
  }, []);

  return (
    <div className="container">
      <div ref={refNewGame} className="new_game">
        <h3>Press the Space to change the direction of motion of the &quot;Defender&quot;</h3>
        <h3>Hold down the Space to increase the speed of the &quot;Defender&quot;</h3>
        <h2 ref={ref} className="game">Start game</h2>
      </div>
      <div className="play_again">
        <h3>
          Your score:
          {' '}
          <span className="result_score" />
        </h3>
        <h2 className="play_again_button">Press the space to play again</h2>
      </div>
      <div className="info score">
        SCORE:
        {' '}
        <span id="score" />
      </div>
      <div className="def">
        <img src={defender} alt="defender" />
      </div>
      <div className="orbit">
        <img src={earth} alt="earth" />
      </div>
      <div className="earth" />
      <div className="wrapped_link"><Link to="/">{"<— "}Back</Link></div>
      <div className="thx">Thanks LEXA for the help</div>
    </div>
  );
};

export default Asteroids;
