import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Header from 'components/Header';
import Main from 'components/Main';
import Footer from 'components/Footer';
import Asteroids from 'components/Asteroids';

import 'utils/i18n';

const App: React.FC = () => {
  const { t } = useTranslation();
  return (

    <Router>
      <Routes>
        <Route
          path="/"
          element={(
            <>
              <Header title={t('navbar.title')} />
              <Main t={t} />
              <Footer />
            </>
          )}
        />
        <Route path="/asteroids" element={<Asteroids />} />
      </Routes>
    </Router>
  );
};

export default App;
