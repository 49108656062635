export const languages = [
  {
    title: 'English',
    level: 'Upper-Intermediate',
  },
  {
    title: 'Russian',
    level: 'Native',
  },
];
