import React from 'react';
import Switcher from 'components/Switcher';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

interface Props {
  title: string;
}

const Header: React.FC<Props> = ({ title }) => {
  const onChangeTheme = (themeValue: 'dark' | 'light'): void => {
    document.documentElement.setAttribute('data-theme', themeValue);
    localStorage.setItem('data-theme', themeValue);
  };
  const isChecked = localStorage.getItem('data-theme') === 'dark';
  return (
    <header>
      <nav>
        <div className={styles.navbar_inner}>
          {title}
          <Link className={styles.link} to="/asteroids">Asteroids</Link>
          <div className={styles.switch_wrapper}>
            <Switcher
              uncheckedValue="light"
              checkedValue="dark"
              onChangeSwitchValue={onChangeTheme}
              label="switch color theme"
              defaultIsTrue={isChecked}
              uncheckedIcon={(
                <div className={`${styles.emoji_icon} ${styles.sun}`} />
              )}
              checkedIcon={
                <div className={`${styles.emoji_icon} ${styles.moon}`} />
              }
            />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
