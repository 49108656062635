import React, { ReactNode } from 'react';
import { contacts } from 'constants/contacts';
import styles from './styles.module.css';

const getContact = (icon: string, value: string, type?: string): ReactNode => {
  switch (type) {
    case 'mail':
      return (
        <a
          className={styles.contact_link}
          href={`mailto:${value}`}
          target="_blank"
          rel="noreferrer"
        >
          <img className={styles.icon} src={icon} alt={`${type}-${value}`} width={30} />
          <span>{value}</span>
        </a>
      );
    case 'link':
      return (
        <a
          className={styles.contact_link}
          href={`https://${value}`}
          target="_blank"
          rel="noreferrer"
        >
          <img className={styles.icon} src={icon} alt={`${type}-${value}`} width={30} />
          <span>{value}</span>
        </a>
      );
    default:
      return (
        <span className={styles.contact}>
          <img className={styles.icon} src={icon} alt={`${type}-${value}`} width={30} />
          <span>{value}</span>
        </span>
      );
  }
};

const ContactList: React.FC = () => (
  <div>
    {contacts.map(({ icon, value, type }) => (
      <div key={value} className={styles.contact_wrapper}>
        {getContact(icon, value, type)}
      </div>
    ))}
  </div>
);

export default ContactList;
