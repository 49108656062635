export const educations = [
  {
    title: 'Functional analysis and analytical economics department',
    university: 'Belarusian State University',
    educationDate: '2012—2017',
  },
  {
    title: 'JavaScript/Front-end Course',
    university: 'The Rolling Scopes',
    educationDate: '2016—2017',
    additionalLink: 'https://rs.school/',
  },
];
