import React from 'react';
import { skills } from 'constants/skills';
import styles from './SkillList.module.css';

const SkillList: React.FC = () => (
  <div className={styles.skill_list}>
    {skills.map(({
      title, isMain,
    }) => (
      <div
        key={title}
        className={`${styles.skill_wrapper} ${isMain && styles.main_skill}`}
      >
        <p className={styles.title}>{title}</p>
      </div>
    ))}
  </div>
);

export default SkillList;
