import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'assets/translations/en.json';

const resources = {
  en,
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next)
  .init({
    resources,
    defaultNS: 'common',
    fallbackLng: 'en',
  });
