import React from 'react';
import { languages } from 'constants/languages';
import styles from './LanguageList.module.css';

const LanguageList: React.FC = () => (
  <div className={styles.language_wrapper}>
    {languages.map(({
      title, level,
    }) => (
      <div key={title}>
        <span className={styles.title}>{title}</span>
        <span className={styles.level}>{level}</span>
      </div>
    ))}
  </div>
);

export default LanguageList;
