export const skills = [
  {
    title: 'Javascript',
    isMain: true,
  },
  {
    title: 'React',
    isMain: true,
  },
  {
    title: 'React Native',
    isMain: true,
  },
  {
    title: 'Node.js',
    isMain: true,
  },
  { title: 'Typescript' },
  { title: 'Websockets' },
  { title: 'HTML' },
  { title: 'CSS' },
  { title: 'sass/less' },
  { title: 'styled-components' },
  { title: 'Redux' },
  { title: 'Eslint' },
  { title: 'Mocha' },
  { title: 'Chai' },
  { title: 'Jest' },
  { title: 'Express.js' },
  { title: 'REST' },
  { title: 'GraphQL' },
  { title: 'MongoDB' },
  { title: 'Mongoose' },
];
