import React, { useState } from 'react';
import Switch from 'react-switch';

interface Props<uncheckedValue extends string, checkedValue extends string> {
  label: string;
  uncheckedIcon?: JSX.Element;
  checkedIcon?: JSX.Element;
  uncheckedValue: uncheckedValue;
  checkedValue: checkedValue;
  defaultIsTrue?: boolean;
  onChangeSwitchValue: (value: uncheckedValue | checkedValue) => void;
}

const Switcher = <uncheckedValue extends string, checkedValue extends string>(
  props: Props<uncheckedValue, checkedValue>,
): JSX.Element => {
  const {
    label,
    uncheckedIcon,
    checkedIcon,
    uncheckedValue,
    checkedValue,
    defaultIsTrue,
    onChangeSwitchValue,
  } = props;
  const [value, setValue] = useState(
    defaultIsTrue ? checkedValue : uncheckedValue,
  );

  const onChange = (checked: boolean): void => {
    const newValue = checked ? checkedValue : uncheckedValue;
    setValue(newValue);
    onChangeSwitchValue(newValue);
  };

  return (
    <Switch
      checked={value === checkedValue}
      onChange={onChange}
      uncheckedIcon={uncheckedIcon}
      checkedIcon={checkedIcon}
      activeBoxShadow="0px 0px 3px 6px rgba(102, 103, 170, 0.4)"
      onColor="#686868"
      offColor="#686868"
      height={24}
      width={54}
      handleDiameter={18}
      aria-label={label}
    />
  );
};

export default Switcher;
